<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Az alkalmazás működése</h2>
      <h3>A tanúsítvány hitelesítésének folyamata</h3>
      <img src="../../src/assets/verify.jpeg" alt="Hitelesítés folyamatábra" />
      <ol class="home-list">
        <li>
          A diák (vagy úgy általában az, aki a tanúsítványt kapja majd) elküldi
          a saját adatait, ami alapján meg lehet állapítani, hogy a tanúsítvány
          az övé. Ebbe beletartozhat a blokkláncban a publikus címe, de a
          rendszer működése szempontjából ez nem egy kötelező elem. Alapvetően
          azok az adatok lényegesek, amelyek a papír alapú változaton is
          szerepelnek.
        </li>
        <li>
          Az intézmény feltölti a webalkalmazásba a képzésnek az adatait
          (például képzés nevét, időpontját, típusát), amelyeknek szintén
          szerepelnie kell a tanúsítványon.
        </li>
        <li>
          Amennyiben a diákok adatai egy külső rendszerből származnak, akkor az
          intézmény feltölti azokat a webalkalmazásba. (Kialakítástól függően,
          ezt közvetlenül a diák is elvégezheti a webalkalmazás segítségével.)
        </li>
        <li>
          A webalkalmazás összeállítja a kapott adatokból a hitelesítendő
          tanúsítványt, majd lementi azokat az adatbázisba.
        </li>
        <li>
          A BC hitelesítő aszinkron lekérdezi az adatbázist, hogy vannak-e benne
          hitelesítésre váró tanúsítványok.
        </li>
        <li>Amennyiben talál ilyet, akkor megkezdi azok hitelesítését.</li>
        <li>
          A BC hitelesítő ellenőrzi a tanúsítvány formátumát (egy sémával
          validálható JSON fájlról van szó), egységes formátumra hozza, majd
          kiszámítja hozzá a hash kódot. Az intézmény blokklánc azonosítói
          segítségével kezdeményezi a hash blokkláncban való tárolását.
        </li>
        <li>
          A BC hitelesítő visszakapja a blokkláncból a hitelesítéshez tartozó
          blokk azonosítóját.
        </li>
        <li>
          A BC hitelesítő összeállítja a hitelesített tanúsítványhoz tartozó
          összes adatot, majd lementi azt az adatbázisba.
        </li>
        <li>
          A BC hitelesítő jelzi a webalkalmazás felé, hogy mely tanúsítványok
          hitelesítésére került sor.
        </li>
        <li>A webalkalmazás lekérdezi ezek adatait az adatbázisból.</li>
        <li>
          Az adatokból összeállítja a tanúsítványt megjeleníthető, letölthető
          formában.
        </li>
        <li>
          A webalkalmazás levélben elküldi a tanúsítványt a diáknak/tanúsítvány
          tulajdonosának.
        </li>
      </ol>
      <h3>A tanúsítvány ellenőrzésének folyamata</h3>
      <img
        src="../../src/assets/check.jpeg"
        alt="A tanúsítvány ellenőrzése folyamatábra"
      />
      <ol class="home-list">
        <li>
          A diák feltölti az alkalmazásba a hitelesítendő tanúsítványt (egy JSON
          formátumú fájlt).
        </li>
        <li>
          A webalkalmazás kiszámítja annak hash kódját, majd ellenőrzi, hogy
          megtalálható-e a blokkláncban.
        </li>
        <li>
          A blokklánc jelzi, hogy a hash kód alapján a tanúsítvány létezik-e
          vagy nem.
        </li>
        <li>A webalkalmazás megjeleníti az ellenőrzés eredményét.</li>
      </ol>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>
<style></style>
