<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Hash kód ellenőrzés (Tanúsítványok)</h2>
      <div>
        <b-table
          id="table"
          table-variant="light"
          selectable
          ref="selectableTable"
          selected-variant="none"
          @row-selected="onRowSelected"
          :fields="fields"
          :items="certifications"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template slot="top-row">
            <td>
              <input
                type="checkbox"
                ref="AllRowSelect"
                @click="checkboxCheckedState"
              />
            </td>
            <td></td>
            <td></td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.institute"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.course"
              />
            </td>
            <td></td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.studentName"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.studentBirth"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.grade"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.certificateDate"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.signed"
              />
            </td>
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span class="check-mark" aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              Részletek {{ row.detailsShowing ? "Elrejtése" : "Mutatása" }}
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"><b>Hash kód</b></b-col>
                <b-col>{{ row.item.bcVerification.hash }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"><b>Hitelesített</b></b-col>
                <b-col>{{
                  row.item.bcVerification.hash != "" ? "&check;" : "&#10060;"
                }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hitelesítés dátuma</b></b-col
                >
                <b-col>{{ row.item.bcVerification.date }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenőrzött</b></b-col
                >
                <b-col>{{
                  row.item.bcVerification.lastCheckDate != ""
                    ? "&check;"
                    : "&#10060;"
                }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenörzés dátuma</b></b-col
                >
                <b-col>{{ row.item.bcVerification.lastCheckDate }}</b-col>
              </b-row>
              <b-row class="mb-2"> </b-row>
            </b-card>
          </template>

          <template #cell(certificationId)="data">{{ data.item.id }} </template>
          <template #cell(institution)="data">{{
            data.item.institute.name
          }}</template>

          <template #cell(qualification)="data"
            >{{ data.item.course.name }}
          </template>

          <template #cell(participantName)="data">
            {{ data.item.student.familyName }}
            {{ data.item.student.firstName }}</template
          >
          <template #cell(participantBirth)="data"
            >{{ data.item.student.birthDate }}
          </template>
          <template #cell(qualificationDate)="data"
            >{{ data.item.course.fromDate }} - {{ data.item.course.toDate }}
          </template>
          <template #cell(certificationGrade)="data">
            {{ data.item.grade.name }}</template
          >
          <template #cell(certificationDate)="data"
            >{{ data.item.certificateDate }}
          </template>
          <template #cell(certificationSigned)="data"
            >{{ data.item.institute.supervisor }}
          </template>
        </b-table>

        <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
        <button class="login-button" id="login-button-style" @click="Hash()">
          Hash ellenörzése
        </button>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// Bootstrap Vue használatával
import errormixin from "@/mixins/errorMixin";
export default {
  name: "ListCertifications",

  data() {
    return {
      fields: [
        { key: "selected", label: "Kiválasztott" },
        { key: "show_details", label: "Részletek" },
        {
          key: "certificationId",
          label: "Tanusítvány sorszáma",
          sortable: true,
        },
        { key: "institution", label: "Intézmény neve", sortable: true },
        { key: "qualification", label: "Képzés neve", sortable: true },
        {
          key: "qualificationDate",
          label: "Képzés időtartama",
          sortable: true,
        },
        { key: "participantName", label: "Képzett neve", sortable: true },
        { key: "participantBirth", label: "Születési dátuma", sortable: true },
        {
          key: "certificationGrade",
          label: "Képzés minősítése",
          sortable: true,
        },
        {
          key: "certificationDate",
          label: "Tanúsítvány kiállításának dátuma",
          sortable: true,
        },
        {
          key: "certificationSigned",
          label: "Tanúsítványt aláírók nevei",
          sortable: true,
        },
      ],
      certifications: [],
      filter: null,
      debounce: null,
      isBusy: true,
      filters: {
        institute: "",
        course: "",
        studentName: "",
        studentBirth: "",
        grade: "",
        certificateDate: "",
        signed: "",
      },
      perPage: 15,
      currentPage: 1,
      errorMessage: "",
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/certificates?isVerified=true")
      .then((response) => {
        this.certifications = response.data;
        console.log("kérés");
        this.isBusy = false;
        this.certifications.forEach((element) => {
          if (element.bcVerification.hash != "") {
            element._rowVariant = "primary";
          }
          if (
            element.bcVerification.date != element.bcVerification.lastCheckDate
          ) {
            element._rowVariant = "success";
          }
        });
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  computed: {
    rows() {
      return this.certifications.length;
    },
  },
  methods: {
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("siker");
        this.isBusy = true;
        this.$http
          .get(
            "/api/certificates?isVerified=true&institute=" +
              this.filters.institute +
              "&course=" +
              this.filters.course +
              "&code=" +
              this.filters.studentId +
              "&studentName=" +
              this.filters.studentName +
              "&studentBirthDate=" +
              this.filters.studentBirth +
              "&grade=" +
              this.filters.grade +
              "&supervisor=" +
              this.filters.signed +
              "&certificateDate=" +
              this.filters.certificateDate
          )
          .then((response) => {
            this.certifications = response.data;
            console.log("kérés");
            this.isBusy = false;
            this.certifications.forEach((element) => {
              if (element.bcVerification.hash != "") {
                element._rowVariant = "primary";
              }
              if (
                element.bcVerification.date !=
                element.bcVerification.lastCheckDate
              ) {
                element._rowVariant = "success";
              }
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      }, 600);
      console.log(this.debounce);
    },
    checkboxCheckedState() {
      if (this.$refs.AllRowSelect.checked == false) {
        this.$refs.selectableTable.clearSelected();
      }
      if (this.$refs.AllRowSelect.checked == true) {
        //do something
        this.$refs.selectableTable.selectAllRows();
      }
    },
    onRowSelected(items) {
      const selectedCertificates = [];
      const probas = [];
      items.forEach((item) => {
        const selectedCertificate = {
          institute: {
            name: item.institute.name,
            supervisor: item.institute.supervisor,
            position: item.institute.position,
          },
          course: {
            name: item.course.name,
            fromDate: item.course.fromDate,
            toDate: item.course.toDate,
          },
          student: {
            code1: item.student.code1,
            code2: item.student.code2,
            familyName: item.student.familyName,
            firstName: item.student.firstName,
            birthDate: item.student.birthDate,
            mothersMaidenName: item.student.mothersMaidenName,
          },
          grade: item.grade.name,
          certificateDate: item.certificateDate,
        };
        const hashIDs = { id: item.id, hash: item.bcVerification.hash };
        probas.push(hashIDs);

        selectedCertificates.push(selectedCertificate);
      });

      this.selectedCertificates = selectedCertificates.map((a) => {
        return { ...a };
      });
      this.hashesWithIDs = probas.map((a) => {
        return { ...a };
      });
      if (Object.keys(this.hashesWithIDs).length != this.perPage) {
        this.$refs.AllRowSelect.checked = false;
      }
      if (Object.keys(this.hashesWithIDs).length == this.perPage) {
        this.$refs.AllRowSelect.checked = true;
      }
    },
    Hash() {
      this.$http
        .post("/api/certificate-hash-codes", this.selectedCertificates)
        .then((response) => {
          this.checkcertificates(response.data);
          this.$refs.selectableTable.clearSelected();
          this.debounceSearch();
          alert("Az ellenörzés megtörtént");

          this.selectedCertificates = [];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkcertificates(serverHashcodes) {
      var hashes = serverHashcodes;

      for (let index = 0; index < this.hashesWithIDs.length; index++) {
        const element = this.hashesWithIDs[index];

        if (element.hash == hashes[index]) {
          this.certifications.find((certificate) => {
            if (certificate.id === element.id) {
              certificate._rowVariant = "success";
            }
          });
        } else {
          this.certifications.find((certificate) => {
            if (certificate.id === element.id) {
              certificate._rowVariant = "danger";
            }
          });
        }
      }
    },
  },
};
</script>

<style></style>
