<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Új vizsga hozzáadása</h2>
      <form action="" class="certification-form">
        <div class="section">
          <p class="create-content">Képző adatai</p>
          <label for="institution-input">Kérem válasszon intézményt</label>
          <b-form-select
            id="institution-input"
            class="select-input mb-3"
            v-model="institute"
            :options="institutions"
            text-field="name"
            value-field="id"
          ></b-form-select>

          <label for="qualification-input">Kérem válasszon képzést</label>
          <b-form-select
            id="qualification-input"
            class="select-input"
            v-model="course"
            :options="qualifications"
            text-field="name"
            value-field="id"
          ></b-form-select>
        </div>
        <div class="section">
          <p class="create-content">Hallgató adatai</p>

          <label for="studentFamilyName-input"
            >Kérem adja meg a hallgató vezetéknevét</label
          >
          <b-form-input
            id="studentFamilyName-input"
            class="text-input"
            type="text"
            v-model="student.familyName"
          >
          </b-form-input>

          <label for="studentFirstName-input"
            >Kérem adja meg a hallgató keresztnevét</label
          >
          <b-form-input
            id="studentFirstName-input"
            class="text-input"
            type="text"
            v-model="student.firstName"
          >
          </b-form-input>

          <label for="studentId1-input"
            >Kérem adja meg a hallgató azonosítóját</label
          >
          <b-form-input
            id="studentId1-input"
            class="text-input"
            type="text"
            v-model="student.code1"
          >
          </b-form-input>

          <label for="studentFirstName-input"
            >Kérem adja meg a hallgató másodlagos azonosítóját</label
          >
          <b-form-input
            id="studentFirstName-input"
            class="text-input"
            type="text"
            v-model="student.code2"
          >
          </b-form-input>

          <label for="studentBirthDate-input"
            >Kérem adja meg a hallgató születési dátumát</label
          >
          <b-form-input
            class="text-input"
            id="studentBirthDate-input"
            type="date"
            v-model="student.birthDate"
          ></b-form-input>

          <label for="studentMotherName-input"
            >Kérem adja meg a hallgató anyja nevét</label
          >
          <b-form-input
            id="studentMotherName-input"
            class="text-input"
            type="text"
            v-model="student.mothersMaidenName"
          >
          </b-form-input>
        </div>

        <div class="section">
          <p class="create-content">Vizsga adatai</p>
          <label for="examType-input">Kérem válasszon vizsga típust</label>

          <b-select
            id="examType-input"
            class="text-input"
            v-model="examinationType"
            :options="examTypes"
            text-field="name"
            value-field="id"
          ></b-select>
          <label for="examGrade-input">Kérem válasszon vizsga minősítést</label>

          <b-form-select
            id="examGrade-input"
            class="text-input"
            v-model="grade"
            :options="examGrades"
            text-field="name"
            value-field="id"
          ></b-form-select>
          <label for="examGrade-input">Kérem válasszon vizsgáztatót</label>
          <b-form-select
            id="teacher-input"
            class="text-input"
            v-model="examiner"
            text-field="name"
            value-field="id"
          >
            <b-form-select-option
              v-for="teacher in teachers"
              :key="teacher.id"
              v-bind:value="teacher.id"
            >
              {{ teacher.familyName }}
              {{ teacher.firstName }}
            </b-form-select-option>
          </b-form-select>
          <label for="examDate-input">Kérem adja meg a vizsga dátumát</label>
          <b-form-input
            id="examDate-input"
            class="text-input"
            type="date"
            v-model="examinationDate"
          ></b-form-input>
        </div>
      </form>
      <button class="login-button" @click="Add()">Vizsga hozzáadása</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateNewExam",
  data() {
    return {
      institute: "",
      examinationType: "",
      grade: "",
      course: "",
      examiner: "",
      student: {
        code1: "",
        code2: "",
        familyName: "",
        firstName: "",
        birthDate: "",
        mothersMaidenName: "",
      },
      examinationDate: "",
      participantName: "",
      students: [],
      institutions: [],
      examTypes: [],
      examGrades: [],
      qualifications: [],
      teachers: [],
    };
  },
  created: function () {
    this.$http.get("api/institutes").then((response) => {
      this.institutions = response.data;
    });
    this.$http.get("api/courses").then((response) => {
      this.qualifications = response.data;
    });
    this.$http.get("api/examination-types").then((response) => {
      this.examTypes = response.data;
    });
    this.$http.get("api/grades").then((response) => {
      this.examGrades = response.data;
    });
    this.$http.get("api/users").then((response) => {
      this.teachers = response.data;
    });
  },
  methods: {
    Add() {
      this.$http
        .post("/api/examinations", {
          institute: { id: this.institute },
          course: { id: this.course },
          student: this.student,
          examinationType: { id: this.examinationType },
          grade: { id: this.grade },
          examiner: { id: this.examiner },
          examinationDate: this.examinationDate,
        })
        .then(
          alert("vizsga létre jött."),
          (this.student.familyName = ""),
          (this.student.firstName = ""),
          (this.student.birthDate = ""),
          (this.student.code1 = ""),
          (this.student.code2 = ""),
          (this.student.mothersMaidenName = ""),
          console.log("elment")
        );
    },
  },
};
</script>

<style></style>
