<template>
  <div id="app">
    <header>
      <router-link v-if="this.user.name != ''" class="" to="/Home">
        <img
          class="nav-pic"
          width="50px"
          src="../src/assets/inno_logo.png"
          alt=""
        />
      </router-link>
      <img
        v-else
        class="nav-pic"
        width="50px"
        src="../src/assets/inno_logo.png"
        alt=""
      />
      <h1 class="main-title">OKTATÁSI EREDMÉNY HITELESÍTÉSI RENDSZER</h1>
      <a href="mailto:info@innocenter.hu">
        <i
          v-if="this.user.name == ''"
          class="bi bi-envelope-fill envelope-right"
          @click="SendMail"
        ></i>
      </a>
    </header>
    <main>
      <div class="nav-background">
        <Nav></Nav>
      </div>
      <router-view />
    </main>
    <Footer></Footer>
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
// //import axios from "axios";
// // Bootstrap Vue használatával
export default {
  components: { Nav, Footer },
  data() {
    return {
      user: this.$token,
    };
  },
  created: function push() {
    if (this.$token.name == "") this.$router.push("Login");
  },
  methods: {
    SendMail() {},
  },
};
</script>

<style>
@import url("./assets/css/proba.css");
</style>
