<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Hash kódolás blockchain hitelesítéssel (Vizsgák)</h2>

      <div>
        <b-table
          id="table"
          table-variant="light"
          sort-icon-left
          selectable
          ref="selectableTable"
          selected-variant="none"
          :fields="fields"
          :items="exams"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              Részletek {{ row.detailsShowing ? "Elrejtése" : "Mutatása" }}
            </b-button>
          </template>
          <template slot="top-row">
            <td>
              <input
                type="checkbox"
                ref="AllRowSelect"
                @click="checkboxCheckedState"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.institute"
              />
            </td>

            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.course"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.studentName"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.studentBirth"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.studentId"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.examinationType"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.grade"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.examiner"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.examinationDate"
              />
            </td>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"><b>Hash kód</b></b-col>
                <b-col sm="2" class="text-sm-right"><b>Hitelesített</b></b-col>
                <b-col sm="2" class="text-sm-right"
                  ><b>Hitelesítés dátuma</b></b-col
                >
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenőrzött</b></b-col
                >
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenörzés dátuma</b></b-col
                >
              </b-row>

              <b-row class="mb-2">
                <b-col>{{ row.item.institution }}</b-col>
              </b-row>
            </b-card>
          </template>

          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span class="check-mark" aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>

          <template #cell(institute)="data"
            >{{ data.item.institute.name }}
          </template>
          <template #cell(course)="data"> {{ data.item.course.name }}</template>
          <template #cell(participantName)="data"
            >{{ data.item.student.familyName }}
            {{ data.item.student.firstName }}
          </template>
          <template #cell(participantBirth)="data"
            >{{ data.item.student.birthDate }}
          </template>
          <template #cell(participantId)="data"
            >{{ data.item.student.code1 }}
          </template>
          <template #cell(examinationType)="data"
            >{{ data.item.examinationType.name }}
          </template>
          <template #cell(grade)="data">{{ data.item.grade.name }} </template>
          <template #cell(examiner)="data"
            >{{ data.item.examiner.familyName }}
            {{ data.item.examiner.firstName }}
          </template>
          <template #cell(examDate)="data">{{ data.item.examDate }} </template>
          <template #cell(edit)="data">
            <b-icon-pencil-fill
              class="floated-right thrash-icon"
              @click="EditListItem(data.item)"
            ></b-icon-pencil-fill>
          </template>
          <template #cell(delete)="data">
            <b-icon-trash-fill
              class="floated-right thrash-icon"
              @click="Remove(data.item.id)"
            ></b-icon-trash-fill>
          </template>
        </b-table>

        <b-modal
          :id="'modal-xl'"
          size="xl"
          title="Vizsga szerkesztése"
          no-stacking
          ok-only
          hide-footer
        >
          <form>
            <div></div>

            <form action="" class="certification-form">
              <div class="section">
                <p class="create-content">Képző adatai</p>
                <label for="institution-input"
                  >Kérem válasszon intézményt</label
                >
                <b-form-select
                  id="institution-input"
                  class="select-input mb-3"
                  v-model="elementEdit.institute"
                  :options="modal.institutions"
                  text-field="name"
                  value-field="id"
                ></b-form-select>

                <label for="qualification-input">Kérem válasszon képzést</label>
                <b-form-select
                  id="qualification-input"
                  class="select-input"
                  v-model="elementEdit.course"
                  :options="modal.qualifications"
                  text-field="name"
                  value-field="id"
                >
                </b-form-select>
              </div>
              <div class="section">
                <p class="create-content">Hallgató adatai</p>

                <label for="studentFamilyName-input"
                  >Kérem adja meg a hallgató vezetéknevét</label
                >
                <b-form-input
                  id="studentFamilyName-input"
                  class="text-input"
                  type="text"
                  v-model="elementEdit.student.familyName"
                >
                </b-form-input>

                <label for="studentFirstName-input"
                  >Kérem adja meg a hallgató keresztnevét</label
                >
                <b-form-input
                  id="studentFirstName-input"
                  class="text-input"
                  type="text"
                  v-model="elementEdit.student.firstName"
                >
                </b-form-input>

                <label for="studentId1-input"
                  >Kérem adja meg a hallgató azonosítóját</label
                >
                <b-form-input
                  id="studentId1-input"
                  class="text-input"
                  type="text"
                  v-model="elementEdit.student.code1"
                >
                </b-form-input>

                <label for="studentFirstName-input"
                  >Kérem adja meg a hallgató másodlagos azonosítóját</label
                >
                <b-form-input
                  id="studentFirstName-input"
                  class="text-input"
                  type="text"
                  v-model="elementEdit.student.code2"
                >
                </b-form-input>

                <label for="studentBirthDate-input"
                  >Kérem adja meg a hallgató születési dátumát</label
                >
                <b-form-input
                  class="text-input"
                  id="studentBirthDate-input"
                  type="date"
                  v-model="elementEdit.student.birthDate"
                ></b-form-input>

                <label for="studentMotherName-input"
                  >Kérem adja meg a hallgató anyja nevét</label
                >
                <b-form-input
                  id="studentMotherName-input"
                  class="text-input"
                  type="text"
                  v-model="elementEdit.student.mothersMaidenName"
                >
                </b-form-input>
              </div>

              <div class="section">
                <p class="create-content">Vizsga adatai</p>
                <label for="examType-input"
                  >Kérem válasszon vizsga típust</label
                >

                <b-select
                  id="examType-input"
                  class="text-input"
                  v-model="elementEdit.examinationType"
                  :options="modal.examTypes"
                  text-field="name"
                  value-field="id"
                ></b-select>
                <label for="examGrade-input"
                  >Kérem válasszon vizsga minősítést</label
                >

                <b-form-select
                  id="examGrade-input"
                  class="text-input"
                  v-model="elementEdit.grade"
                  :options="modal.examGrades"
                  text-field="name"
                  value-field="id"
                ></b-form-select>
                <label for="examGrade-input"
                  >Kérem válasszon vizsgáztatót</label
                >
                <b-form-select
                  id="teacher-input"
                  class="text-input"
                  v-model="elementEdit.examiner"
                  text-field="name"
                  value-field="id"
                >
                  <b-form-select-option
                    v-for="teacher in modal.teachers"
                    :key="teacher.id"
                    v-bind:value="teacher.id"
                  >
                    {{ teacher.familyName }}
                    {{ teacher.firstName }}
                  </b-form-select-option>
                </b-form-select>

                <label for="examDate-input"
                  >Kérem adja meg a vizsga dátumát</label
                >
                <b-form-input
                  id="examDate-input"
                  class="text-input"
                  type="date"
                  v-model="elementEdit.examinationDate"
                ></b-form-input>
              </div>
            </form>
            <button class="login-button add-button" @click="Edit" block>
              Mentés
            </button>
            <button class="login-button add-button" @click="Close" block>
              Mégse
            </button>
          </form>
        </b-modal>
        <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
        <button class="login-button" id="login-button-style" @click="Hash">
          Hash létrehozása
        </button>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import errormixin from "@/mixins/errorMixin";
// Bootstrap Vue használatával
export default {
  name: "ListExams",

  data() {
    return {
      fields: [
        { key: "selected", label: "Kiválasztott" },
        //{ key: "show_details", label: "Részletek" },
        { key: "institute", label: "Intézmény neve", sortable: true },
        { key: "course", label: "Képzés neve" },
        { key: "participantName", label: "Hallgató neve", sortable: true },
        { key: "participantBirth", label: "Születési dátuma" },
        { key: "participantId", label: "Azonosítója" },
        { key: "examinationType", label: "Vizsga típusa" },
        { key: "grade", label: "Vizsga minősítése" },
        { key: "examiner", label: "Vizsgáztató neve" },
        { key: "examinationDate", label: "Vizsga dátuma" },
        { key: "edit", label: "" },
        { key: "delete", label: "" },
      ],
      exams: [],
      selectedExams: [],
      perPage: 15,
      currentPage: 1,
      errorMessage: "",
      debounce: null,
      isBusy: true,
      filter: null,
      filters: {
        institute: "",
        course: "",
        studentName: "",
        studentBirth: "",
        studentId: "",
        examinationType: "",
        grade: "",
        examiner: "",
        examinationDate: "",
      },
      modal: {
        institutions: [],
        examTypes: [],
        examGrades: [],
        qualifications: [],
        teachers: [],
      },
      elementEditId: "",
      elementEdit: {
        institute: "",
        examinationType: "",
        grade: "",
        course: "",
        examiner: "",
        student: {
          code1: "",
          code2: "",
          familyName: "",
          firstName: "",
          birthDate: "",
          mothersMaidenName: "",
        },
        examinationDate: "",
      },
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/examinations?isVerified=false")
      .then((response) => {
        this.exams = response.data;
        this.isBusy = false;
        // this.exams.forEach((element) => {
        //   if (element.bcVerification.hash == "") {
        //     element._rowVariant = "warning";
        //   }
        // });
      })
      .catch((error) => {
        this.handleError(error);
      });
    this.$http.get("api/institutes").then((response) => {
      this.modal.institutions = response.data;
    });
    this.$http.get("api/courses").then((response) => {
      this.modal.qualifications = response.data;
    });
    this.$http.get("api/examination-types").then((response) => {
      this.modal.examTypes = response.data;
    });
    this.$http.get("api/grades").then((response) => {
      this.modal.examGrades = response.data;
    });
    this.$http.get("api/users").then((response) => {
      this.modal.teachers = response.data;
    });
  },
  computed: {
    rows() {
      return this.exams.length;
    },
  },
  methods: {
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("siker");
        this.isBusy = true;
        this.$http
          .get(
            "/api/examinations?isVerified=false&institute=" +
              this.filters.institute +
              "&course=" +
              this.filters.course +
              "&code=" +
              this.filters.studentId +
              "&studentName=" +
              this.filters.studentName +
              "&studentBirthDate=" +
              this.filters.studentBirth +
              "&examinationType=" +
              this.filters.examinationType +
              "&grade=" +
              this.filters.grade +
              "&examinerName=" +
              this.filters.examiner +
              "&examinationDate=" +
              this.filters.examinationDate
          )
          .then((response) => {
            this.exams = response.data;
            console.log("kérés");
            this.isBusy = false;
            this.exams.forEach((element) => {
              if (element.bcVerification.hash != "") {
                element._rowVariant = "primary";
              }
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      }, 1000);
      console.log(this.debounce);
    },
    checkboxCheckedState() {
      if (this.$refs.AllRowSelect.checked == false) {
        this.$refs.selectableTable.clearSelected();
      }
      if (this.$refs.AllRowSelect.checked == true) {
        //do something
        this.$refs.selectableTable.selectAllRows();
      }
    },

    onRowSelected(items) {
      const probas = [];
      items.forEach((item) => {
        probas.push(item.id);
      });
      this.selectedExams = probas;
      if (this.selectedExams.length != this.perPage) {
        this.$refs.AllRowSelect.checked = false;
      }
      if (this.selectedExams.length == this.perPage) {
        this.$refs.AllRowSelect.checked = true;
      }
    },
    EditListItem(item) {
      this.elementEditId = item.id;
      this.elementEdit.institute = item.institute.id;
      this.elementEdit.course = item.course.id;
      this.elementEdit.examinationType = item.examinationType.id;
      this.elementEdit.grade = item.grade.id;
      this.elementEdit.examiner = item.examiner.id;
      this.elementEdit.student = item.student;
      this.elementEdit.examinationDate = item.examinationDate;

      this.$bvModal.show("modal-xl");
    },
    Refresh() {
      this.$http
        .get("/api/examinations?isVerified=false")
        .then((response) => {
          this.exams = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    Edit() {
      this.isBusy = true;
      this.$http
        .put("api/examinations/" + this.elementEditId, {
          institute: { id: this.elementEdit.institute },
          course: { id: this.elementEdit.course },
          student: this.elementEdit.student,
          examinationType: { id: this.elementEdit.examinationType },
          grade: { id: this.elementEdit.grade },
          examiner: { id: this.elementEdit.examiner },
          examinationDate: this.elementEdit.examinationDate,
        })
        .then(
          this.$http
            .get("/api/examinations?isVerified=false")
            .then((response) => {
              this.exams = response.data;
              this.isBusy = false;
            })
            .catch((error) => {
              this.handleError(error);
            })
        );
      this.$bvModal.hide("modal-xl");
    },
    Remove(index) {
      if (confirm("Biztos törli a vizsgát?")) {
        this.$http.delete("api/examinations/" + index);
        this.Refresh();
      }
    },
    Close() {
      this.$bvModal.hide("modal-xl");
    },
    Hash() {
      this.$http.put("/api/examinations", this.selectedExams).then(
        (this.isBusy = true),
        alert("Sikeresen létre jött a hash kód"),
        this.$http
          .get("/api/examinations?isVerified=false")
          .then((response) => {
            this.exams = response.data;
            this.isBusy = false;
          })
          .catch((error) => {
            this.handleError(error);
          })
      );
    },
  },
};
</script>

<style></style>
