import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ListExams from "../views/ListExams";
import ListCertifications from "../views/ListCertifications";

import ExamGradeListEditor from "../components/ListEditors/ExamGradeListEditor";
import ExamTypeListEditor from "../components/ListEditors/ExamTypeListEditor";
import InstitutionListEditor from "../components/ListEditors/InstitutionListEditor";
import QualificationListEditor from "../components/ListEditors/CourseListEditor";
import TeachersListEditor from "../components/ListEditors/TeachersListEditor";

import CreateNewExam from "../components/CreateNewExam";
import CreateNewCertification from "../components/CreateNewCertification";

import CreateHashFromCertificate from "../components/HashValidation/CreateHashFromCertificate";
import CreateHashFromExam from "../components/HashValidation/CreateHashFromExam";
import CertificateHashValidation from "../components/HashValidation/CertificateHashValidation";
import ExamHashValidation from "../components/HashValidation/ExamHashValidation";

import Registration from "../components/Registration";
Vue.use(VueRouter);

const routes = [{
        path: "/Home",
        name: "Home",
        component: Home,
    },

    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: "/registration",
        name: "Registration",
        component: Registration,
    },
    {
        path: "/CreateNewExam",
        name: "CreateNewExam",
        component: CreateNewExam,
    },
    {
        path: "/CreateNewCertification",
        name: "CreateNewCertification",
        component: CreateNewCertification,
    },
    {
        path: "/ListExams",
        name: "ListExams",
        component: ListExams,
    },
    {
        path: "/ListCertifications",
        name: "ListCertifications",
        component: ListCertifications,
    },
    {
        path: "/InstitutionListEditor",
        name: "InstitutionListEditor",
        component: InstitutionListEditor,
    },
    {
        path: "/ExamGradeListEditor",
        name: "ExamGradeListEditor",
        component: ExamGradeListEditor,
    },
    {
        path: "/ExamTypeListEditor",
        name: "ExamTypeListEditor",
        component: ExamTypeListEditor,
    },
    {
        path: "/QualificationListEditor",
        name: "QualificationListEditor",
        component: QualificationListEditor,
    },
    {
        path: "/TeachersListEditor",
        name: "TeachersListEditor",
        component: TeachersListEditor,
    },
    {
        path: "/CreateHashFromExam",
        name: "CreateHashFromExam",
        component: CreateHashFromExam,
    },
    {
        path: "/CreateHashFromCertifacate",
        name: "CreateHashFromCertificate",
        component: CreateHashFromCertificate,
    },

    {
        path: "/ExamHashValidation",
        name: "ExamHashValidation",
        component: ExamHashValidation,
    },

    {
        path: "/CertificateHashValidation",
        name: "CertificateHashValidation",
        component: CertificateHashValidation,
    },
];

const router = new VueRouter({
    routes,
});

export default router;