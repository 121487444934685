<template>
  <div class="page">
    <div class="content list-editor">
      <h2>Vizsgatípusok</h2>
      <form action="">
        <b-table
          thead-class="hidden_header"
          id="table"
          class="table-content"
          table-variant="light"
          :fields="fields"
          :items="this.list"
          :per-page="perPage"
          :current-page="currentPage"
          ref="table"
        >
          <template #cell(examType)="data">
            {{ data.item.name
            }}<b-icon-trash-fill
              class="floated-right thrash-icon"
              @click="Remove(data.item.id, data.item.name)"
            ></b-icon-trash-fill>
            <b-icon-pencil-fill
              class="floated-right thrash-icon"
              @click="EditListItem(data.item)"
            ></b-icon-pencil-fill>
          </template>
        </b-table>

        <b-modal
          :id="'modal-center'"
          title="Képzés szerkesztése"
          no-stacking
          ok-only
          hide-footer
        >
          <form class="inline-elements">
            <div>
              <input type="text" v-model="elementEdit.name" />
            </div>
            <button class="login-button add-button" @click="Edit" block>
              Mentés
            </button>
          </form>
        </b-modal>
        <b-pagination
          variant="dark"
          class="pagination-place"
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
        <span>{{ errorMessage }}</span>
        <h3>Új Vizsgatípus hozzáadása</h3>
        <div class="inline-elements">
          <input
            placeholder="vizsgatípus"
            type="text"
            class="list-editor-input"
            v-model="element"
            v-on:keyup.enter="Add"
          />
          <button
            class="login-button add-button"
            :disabled="IsDisabled"
            @click="Add"
          >
            Vizsgatípus hozzáadása
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormixin from "@/mixins/errorMixin";
export default {
  name: "ExamTypeListEditor",

  data() {
    return {
      fields: [{ key: "examType", label: "Vizsga típusa", sortable: true }],
      list: [],
      perPage: 10,
      currentPage: 1,
      errorMessage: "",
      element: "",
      elementEdit: { id: "", name: "" },
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/examination-types")
      .then((response) => {
        this.list = response.data;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  computed: {
    rows() {
      return this.list.length;
    },
    IsDisabled: function () {
      if (this.element == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    EditListItem(item) {
      this.elementEdit.id = item.id;
      this.elementEdit.name = item.name;
      this.$bvModal.show("modal-center");
    },
    Refresh() {
      this.$http
        .get("/api/examination-types")
        .then((response) => {
          this.list = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    Add() {
      this.element = this.element.trim();
      if (this.element != "") {
        this.$http.post("api/examination-types", { name: this.element }).then();

        this.Refresh();

        this.element = "";
      }
    },
    Edit() {
      this.$http
        .put("api/examination-types/" + this.elementEdit.id, {
          name: this.elementEdit.name,
        })
        .then();
      this.$bvModal.hide("modal-center");
      this.Refresh();
    },
    Remove(index, element) {
      if (confirm("Biztos törli a(z) " + element + " vizsgatípust?")) {
        this.$http.delete("api/examination-types/" + index);
        this.Refresh();
      }
    },
  },
};
</script>
