<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Új Tanúsítvány hozzáadása</h2>
      <form action="" class="certification-form">
        <div class="section">
          <p class="create-content">Képző adatai</p>

          <label for="institution-input">Kérem válasszon intézményt&#42;</label>
          <b-form-select
            id="institution-input"
            class="select-input"
            v-model="institute"
            :options="institutes"
            text-field="name"
            value-field="id"
          ></b-form-select>

          <label for="qualification-input">Kérem válasszon képzést &#42;</label>
          <b-form-select
            id="qualification-input"
            class="select-input"
            v-model="course"
            :options="courses"
            text-field="name"
            value-field="id"
          ></b-form-select>

          <label for="qualificationDateFrom-input"
            >Kérem adja meg a képzés kezdetét &#42;</label
          >
          <b-form-input
            class="text-input"
            id="qualificationDateFrom-input"
            type="date"
            v-model="fromDate"
          ></b-form-input>

          <label for="qualificationDateTo-input"
            >Kérem adja meg a képzés végét &#42;</label
          >
          <b-form-input
            class="text-input"
            id="qualificationDateTo-input"
            type="date"
            v-model="toDate"
          ></b-form-input>
        </div>

        <div class="section">
          <p class="create-content">Hallgató adatai</p>

          <label for="studentFamilyName-input"
            >Kérem adja meg a hallgató vezetéknevét</label
          >
          <b-form-input
            id="studentFamilyName-input"
            class="text-input"
            type="text"
            v-model="student.familyName"
          >
          </b-form-input>

          <label for="studentFirstName-input"
            >Kérem adja meg a hallgató keresztnevét</label
          >
          <b-form-input
            id="studentFirstName-input"
            class="text-input"
            type="text"
            v-model="student.firstName"
          >
          </b-form-input>

          <label for="studentId1-input"
            >Kérem adja meg a hallgató azonosítóját</label
          >
          <b-form-input
            id="studentId1-input"
            class="text-input"
            type="text"
            v-model="student.code1"
          >
          </b-form-input>

          <label for="studentFirstName-input"
            >Kérem adja meg a hallgató másodlagos azonosítóját</label
          >
          <b-form-input
            id="studentFirstName-input"
            class="text-input"
            type="text"
            v-model="student.code2"
          >
          </b-form-input>

          <label for="studentBirthDate-input"
            >Kérem adja meg a hallgató születési dátumát</label
          >
          <b-form-input
            class="text-input"
            id="studentBirthDate-input"
            type="date"
            v-model="student.birthDate"
          ></b-form-input>

          <label for="studentMotherName-input"
            >Kérem adja meg a hallgató anyja nevét</label
          >
          <b-form-input
            id="studentMotherName-input"
            class="text-input"
            type="text"
            v-model="student.mothersMaidenName"
          >
          </b-form-input>
        </div>

        <div class="section">
          <p class="create-content">Vizsga adatai</p>
          <label for="certificationGrade-input"
            >Kérem válasszon vizsgajegyet</label
          >
          <b-form-select
            id="certificationGrade-input"
            class="select-input"
            v-model="grade"
            :options="grades"
            text-field="name"
            value-field="id"
          >
          </b-form-select>

          <label for="certificationDate-input"
            >Kérem adja meg a vizsda dátumát</label
          >
          <b-form-input
            id="certificationDate-input"
            class="text-input"
            type="date"
            v-model="certificateDate"
          ></b-form-input>

          <label for="teacher-input">Kérem válasszon vizsgáztatót</label>
          <b-form-select
            id="teacher-input"
            class="text-input"
            v-model="examiner"
            text-field="name"
            value-field="id"
          >
            <b-form-select-option
              v-for="examiner in examiners"
              :key="examiner.id"
              v-bind:value="examiner.id"
            >
              {{ examiner.familyName }}
              {{ examiner.firstName }}
            </b-form-select-option>
          </b-form-select>
        </div>
      </form>
      <button class="login-button" @click="Add">Tanúsítvány hozzáadása</button>
    </div>
  </div>
</template>

<script>
import errormixin from "@/mixins/errorMixin";
export default {
  name: "CreateNewCertification",
  data() {
    return {
      institute: "",
      course: "",
      fromDate: "",
      toDate: "",
      student: {
        code1: "",
        code2: "",
        familyName: "",
        firstName: "",
        birthDate: "",
        mothersMaidenName: "",
      },
      qualificationDate: "",
      grade: "",
      certificateDate: "",
      signers: "",
      examiner: "",
      institutes: [],
      grades: [],
      courses: [],
      examiners: [],
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http.get("api/institutes").then((response) => {
      this.institutes = response.data;
    });
    this.$http.get("api/courses").then((response) => {
      this.courses = response.data;
    });

    this.$http.get("api/grades").then((response) => {
      this.grades = response.data;
    });
    this.$http.get("api/users").then((response) => {
      this.examiners = response.data;
    });
  },
  methods: {
    Add() {
      this.$http
        .post("/api/certificates", {
          institute: { id: this.institute },
          course: {
            id: this.course,
            fromDate: this.fromDate,
            toDate: this.toDate,
          },
          student: this.student,
          grade: { id: this.grade },
          certificateDate: this.certificateDate,
        })
        .then(
          console.log("elment"),
          alert("tanősítvány létre jött."),
          (this.student.familyName = ""),
          (this.student.firstName = ""),
          (this.student.birthDate = ""),
          (this.student.code1 = ""),
          (this.student.code2 = ""),
          (this.student.mothersMaidenName = ""),
          console.log("elment")
        );
    },
  },
};
</script>

<style></style>
