<template>
  <div class="login-box">
    <h2>Regisztráció</h2>
    <form class="input-form">
      <b-form-input
        type="text"
        id="password"
        v-model="user.firstName"
        placeholder="Keresztnév"
      />
      <b-form-input
        type="text"
        id="password"
        v-model="user.familyName"
        placeholder="Vezteknév"
      />
      <b-form-input
        type="text"
        id="password"
        v-model="user.email"
        placeholder="E-mail"
      />
      <b-form-input
        type="text"
        v-model="user.name"
        id="username"
        placeholder="Felhasználónév:"
      />

      <b-form-input
        type="password"
        id="password"
        v-model="user.password"
        placeholder="Jelszó:"
      />
      <span>
        {{ errorMessage }}
      </span>
      <b-form-input
        type="password"
        id="password"
        v-model="confirmPassword"
        placeholder="Jelszó megerősítése:"
      />
      <b-form-input
        type="text"
        id="password"
        v-model="user.phone"
        placeholder="Telefonszám"
      />
      <b-form-datepicker
        type="date"
        id="date"
        v-model="user.birthDate"
        placeholder="Születési dátum"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
      />
      <button class="login-button" id="login-button-style" v-on:click="signIn">
        Regisztráció
      </button>
    </form>
  </div>
</template>

<script>
//import "./assets/styles/styles.css";

export default {
  name: "Login",
  data() {
    return {
      user: {
        name: "",
        password: "",
        familyName: "",
        firstName: "",
        email: "",
        phone: "",
        birthDate: "",
      },
      confirmPassword: "",
      errorMessage: "",
    };
  },
  methods: {
    signIn() {
      if (this.validate()) {
        this.$http
          .post("/api/users", this.user)

          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errorMessage = "már létezik ilyen email/felhasználónév";
                alert(this.errorMessage);
                break;
              case 404:
                this.errorMessage = "A weboldal nem elérhető";
                alert(this.errorMessage);
                break;
              case 504:
                this.errorMessage = "Nincs szerver kapcsolat";
                alert(this.errorMessage);
                break;

              default:
                this.errorMessage = "Valami hiba történt...";
                alert(this.errorMessage);
            }
          });
      } else {
        this.errorMessage = "a két jelszó nem egyezik";
      }
    },
    validate() {
      if (this.user.password == this.confirmPassword) {
        return true;
      }
    },
  },
};
</script>
<style scoped>
span {
  color: red;
}
</style>
