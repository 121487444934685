<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Tanúsítványok</h2>
      <div>
        <b-table
          id="table"
          table-variant="light"
          :fields="fields"
          :items="certifications"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template slot="top-row">
            <td></td>
            <td></td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.institute"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.course"
              />
            </td>
            <td></td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.studentName"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.studentBirth"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.grade"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.certificateDate"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.signed"
              />
            </td>
          </template>
          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              Részletek {{ row.detailsShowing ? "Elrejtése" : "Mutatása" }}
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"><b>Hash kód</b></b-col>
                <b-col>{{ row.item.bcVerification.hash }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"><b>Hitelesített</b></b-col>
                <b-col>{{
                  row.item.bcVerification.hash != "" ? "&check;" : "&#10060;"
                }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hitelesítés dátuma</b></b-col
                >
                <b-col>{{ row.item.bcVerification.date }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenőrzött</b></b-col
                >
                <b-col>{{
                  row.item.bcVerification.lastCheckDate != ""
                    ? "&check;"
                    : "&#10060;"
                }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenörzés dátuma</b></b-col
                >
                <b-col>{{ row.item.bcVerification.lastCheckDate }}</b-col>
              </b-row>
              <b-row class="mb-2"> </b-row>
            </b-card>
          </template>

          <template #cell(certificationId)="data">{{ data.item.id }} </template>
          <template #cell(institution)="data">{{
            data.item.institute.name
          }}</template>

          <template #cell(qualification)="data"
            >{{ data.item.course.name }}
          </template>

          <template #cell(participantName)="data">
            {{ data.item.student.familyName }}
            {{ data.item.student.firstName }}</template
          >
          <template #cell(participantBirth)="data"
            >{{ data.item.student.birthDate }}
          </template>
          <template #cell(qualificationDate)="data"
            >{{ data.item.course.fromDate }} - {{ data.item.course.toDate }}
          </template>
          <template #cell(certificationGrade)="data">
            {{ data.item.grade.name }}</template
          >
          <template #cell(certificationDate)="data"
            >{{ data.item.certificateDate }}
          </template>
          <template #cell(certificationSigned)="data"
            >{{ data.item.institute.supervisor }}
          </template>
          <template #cell(edit)="data">
            <template v-if="data.item.bcVerification.hash == ''">
              <b-icon-pencil-fill
                class="floated-right thrash-icon"
                @click="EditListItem(data.item)"
              ></b-icon-pencil-fill>
            </template>
            <template v-else> </template>
          </template>
        </b-table>
        <b-modal
          :id="'modal-xl'"
          size="xl"
          title="Tanúsítvány szerkesztése"
          no-stacking
          ok-only
          hide-footer
        >
          <form action="" class="certification-form">
            <div class="section">
              <p class="create-content">Képző adatai</p>

              <label for="institution-input"
                >Kérem válasszon intézményt &#42;</label
              >
              <b-form-select
                id="institution-input"
                class="select-input"
                v-model="elementEdit.institute.id"
                :options="modal.institutions"
                text-field="name"
                value-field="id"
              ></b-form-select>

              <label for="qualification-input"
                >Kérem válasszon képzést &#42;</label
              >
              <b-form-select
                id="qualification-input"
                class="select-input"
                v-model="elementEdit.course.id"
                :options="modal.courses"
                text-field="name"
                value-field="id"
              ></b-form-select>

              <label for="qualificationDateFrom-input"
                >Kérem adja meg a képzés kezdetét &#42;</label
              >
              <b-form-input
                class="text-input"
                id="qualificationDateFrom-input"
                type="date"
                v-model="elementEdit.course.fromDate"
              ></b-form-input>

              <label for="qualificationDateTo-input"
                >Kérem adja meg a képzés végét &#42;</label
              >
              <b-form-input
                class="text-input"
                id="qualificationDateTo-input"
                type="date"
                v-model="elementEdit.course.toDate"
              ></b-form-input>
            </div>

            <div class="section">
              <p class="create-content">Hallgató adatai</p>

              <label for="studentFamilyName-input"
                >Kérem adja meg a hallgató vezetéknevét</label
              >
              <b-form-input
                id="studentFamilyName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.familyName"
              >
              </b-form-input>

              <label for="studentFirstName-input"
                >Kérem adja meg a hallgató keresztnevét</label
              >
              <b-form-input
                id="studentFirstName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.firstName"
              >
              </b-form-input>

              <label for="studentId1-input"
                >Kérem adja meg a hallgató azonosítóját</label
              >
              <b-form-input
                id="studentId1-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.code1"
              >
              </b-form-input>

              <label for="studentFirstName-input"
                >Kérem adja meg a hallgató másodlagos azonosítóját</label
              >
              <b-form-input
                id="studentFirstName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.code2"
              >
              </b-form-input>

              <label for="studentBirthDate-input"
                >Kérem adja meg a hallgató születési dátumát</label
              >
              <b-form-input
                class="text-input"
                id="studentBirthDate-input"
                type="date"
                v-model="elementEdit.student.birthDate"
              ></b-form-input>

              <label for="studentMotherName-input"
                >Kérem adja meg a hallgató anyja nevét</label
              >
              <b-form-input
                id="studentMotherName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.mothersMaidenName"
              >
              </b-form-input>
            </div>

            <div class="section">
              <p class="create-content">Vizsga adatai</p>
              <label for="certificationGrade-input"
                >Kérem válasszon vizsgajegyet</label
              >
              <b-form-select
                id="certificationGrade-input"
                class="select-input"
                v-model="elementEdit.grade.id"
                :options="modal.grades"
                text-field="name"
                value-field="id"
              >
              </b-form-select>

              <label for="certificationDate-input"
                >Kérem adja meg a vizsda dátumát</label
              >
              <b-form-input
                id="certificationDate-input"
                class="text-input"
                type="date"
                v-model="elementEdit.certificateDate"
              ></b-form-input>

              <!-- <label for="teacher-input">Kérem válasszon vizsgáztatót</label>
              <b-form-select
                id="teacher-input"
                class="text-input"
                v-model="elementEdit.examiner"
                text-field="name"
                value-field="id"
              >
                <b-form-select-option
                  v-for="examiner in modal.examiners"
                  :key="examiner.id"
                  v-bind:value="examiner.id"
                >
                  {{ examiner.familyName }}
                  {{ examiner.firstName }}
                </b-form-select-option>
              </b-form-select> -->
            </div>
          </form>
          <button class="login-button add-button" @click="Edit" block>
            Mentés
          </button>
          <button class="login-button add-button" @click="Close" block>
            Mégse
          </button>
        </b-modal>
        <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// Bootstrap Vue használatával
import errormixin from "../mixins/errorMixin";
export default {
  name: "ListCertifications",

  data() {
    return {
      fields: [
        { key: "show_details", label: "Részletek" },

        {
          key: "certificationId",
          label: "Tanusítvány sorszáma",
          sortable: true,
        },
        { key: "institution", label: "Intézmény neve", sortable: true },
        { key: "qualification", label: "Képzés neve", sortable: true },
        {
          key: "qualificationDate",
          label: "Képzés időtartama",
          sortable: true,
        },
        { key: "participantName", label: "Képzett neve", sortable: true },
        { key: "participantBirth", label: "Születési dátuma", sortable: true },
        {
          key: "certificationGrade",
          label: "Képzés minősítése",
          sortable: true,
        },
        {
          key: "certificationDate",
          label: "Tanúsítvány kiállításának dátuma",
          sortable: true,
        },
        {
          key: "certificationSigned",
          label: "Tanúsítványt aláíró neve",
          sortable: true,
        },
        { key: "edit", label: "" },
      ],
      certifications: [],
      modal: {
        institutions: [],
        examTypes: [],
        grades: [],
        courses: [],
        examiners: [],
      },
      elementEditId: "",
      elementEdit: {
        institute: {
          id: "",
        },
        course: {
          id: "",
          fromDate: "",
          toDate: "",
        },
        student: {
          code1: "",
          code2: "",
          familyName: "",
          firstName: "",
          birthDate: "",
          mothersMaidenName: "",
        },
        grade: { id: "" },
        certificateDate: "",
      },
      isBusy: true,
      debounce: null,
      filter: null,
      filters: {
        institute: "",
        course: "",
        studentName: "",
        studentBirth: "",
        grade: "",
        certificateDate: "",
        signed: "",
      },
      perPage: 15,
      currentPage: 1,
      errorMessage: "",
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/certificates")
      .then((response) => {
        this.certifications = response.data;

        this.certifications.forEach((element) => {
          if (element.bcVerification.hash != "") {
            element._rowVariant = "primary";
          }
          if (
            element.bcVerification.date != element.bcVerification.lastCheckDate
          ) {
            element._rowVariant = "success";
          }
        });
        this.isBusy = false;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  computed: {
    rows() {
      return this.certifications.length;
    },
  },
  methods: {
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("siker");
        this.isBusy = true;
        this.$http
          .get(
            "/api/certificates?institute=" +
              this.filters.institute +
              "&course=" +
              this.filters.course +
              "&code=" +
              this.filters.studentId +
              "&studentName=" +
              this.filters.studentName +
              "&studentBirthDate=" +
              this.filters.studentBirth +
              "&grade=" +
              this.filters.grade +
              "&supervisor=" +
              this.filters.signed +
              "&certificateDate=" +
              this.filters.certificateDate
          )
          .then((response) => {
            this.certifications = response.data;
            console.log("kérés");
            this.isBusy = false;
            this.certifications.forEach((element) => {
              if (element.bcVerification.hash != "") {
                element._rowVariant = "primary";
              }
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      }, 600);
      console.log(this.debounce);
    },
    EditListItem(item) {
      this.$http.get("api/institutes").then((response) => {
        this.modal.institutions = response.data;
      });
      this.$http.get("api/courses").then((response) => {
        this.modal.courses = response.data;
      });
      this.$http.get("api/examination-types").then((response) => {
        this.modal.examTypes = response.data;
      });
      this.$http.get("api/grades").then((response) => {
        this.modal.grades = response.data;
      });

      this.elementEditId = item.id;
      this.elementEdit.institute.id = item.institute.id;
      this.elementEdit.course.id = item.course.id;
      this.elementEdit.course.fromDate = item.course.fromDate;
      this.elementEdit.course.toDate = item.course.toDate;
      this.elementEdit.student = item.student;
      this.elementEdit.grade.id = item.grade.id;
      this.elementEdit.certificateDate = item.certificateDate;

      this.$bvModal.show("modal-xl");
    },
    Edit() {
      this.$http
        .put("api/certificates/" + this.elementEditId, this.elementEdit)
        .then(
          (this.isBusy = true),
          this.$http
            .get("/api/certificates")
            .then((response) => {
              this.certifications = response.data;
              this.certifications.forEach((element) => {
                if (element.bcVerification.hash != "") {
                  element._rowVariant = "primary";
                }
              });
              this.isBusy = false;
            })
            .catch((error) => {
              this.handleError(error);
            })
        );
      this.$bvModal.hide("modal-xl");
    },
    Close() {
      this.$bvModal.hide("modal-xl");
    },
  },
};
</script>

<style>
ul {
  list-style: none;
  display: inline;
}

link {
  color: #3e403f;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
div,
td {
  color: #373378 !important;
}
</style>
