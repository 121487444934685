<template>
  <div class="container-login">
    <div class="login-box">
      <h2>Bejelentkezés</h2>

      <input
        class="login-input"
        type="text"
        v-model="user.username"
        id="username"
        placeholder="Felhasználónév:"
      />

      <input
        class="login-input"
        type="password"
        id="password"
        v-model="user.password"
        v-on:keyup.enter="signIn"
        placeholder="Jelszó:"
      />
      <label class="errorForInput" for="password">{{
        this.errorMessage
      }}</label>
      <button class="login-button" id="login-button-style" v-on:click="signIn">
        bejelentkezés
      </button>
      <!-- <div>
          <b-link to="/registration">Regisztráció</b-link>
        </div> -->
    </div>
  </div>
</template>

<script>
//import "./assets/styles/styles.css";
import errormixins from "../mixins/errorMixin.js";
export default {
  name: "Login",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      errorMessage: "",
    };
  },
  mixins: [errormixins],
  methods: {
    signIn() {
      this.$http
        .post("/api/login", {
          username: this.user.username,
          password: this.user.password,
        })
        .then((result) => {
          console.log(result.data);
          this.$token.name = result.data.name;
          var token = result.data.token;
          this.$token.token = token;
          this.$http.defaults.headers.common["Authorization"] = token;
          console.log(this.$token.token);
          this.$router.push("Home");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
<style></style>
