<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Hash kódolás blockchain hitelesítéssel (Tanúsítványok)</h2>
      <div>
        <b-table
          id="table"
          table-variant="light"
          selectable
          ref="selectableTable"
          selected-variant="none"
          @row-selected="onRowSelected"
          :fields="fields"
          :items="certifications"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template slot="top-row">
            <td>
              <input
                type="checkbox"
                ref="AllRowSelect"
                @click="checkboxCheckedState"
              />
            </td>
            <td></td>

            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.institute"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.course"
              />
            </td>
            <td></td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.studentName"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.studentBirth"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.grade"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.certificateDate"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.signed"
              />
            </td>
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span class="check-mark" aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>

          <template #cell(certificationId)="data">{{ data.item.id }} </template>
          <template #cell(institution)="data">{{
            data.item.institute.name
          }}</template>

          <template #cell(qualification)="data"
            >{{ data.item.course.name }}
          </template>

          <template #cell(participantName)="data">
            {{ data.item.student.familyName }}
            {{ data.item.student.firstName }}</template
          >
          <template #cell(participantBirth)="data"
            >{{ data.item.student.birthDate }}
          </template>
          <template #cell(qualificationDate)="data"
            >{{ data.item.course.fromDate }} - {{ data.item.course.toDate }}
          </template>
          <template #cell(certificationGrade)="data">
            {{ data.item.grade.name }}</template
          >
          <template #cell(certificationDate)="data"
            >{{ data.item.certificateDate }}
          </template>
          <template #cell(certificationSigned)="data"
            >{{ data.item.institute.supervisor }}
          </template>
          <template #cell(edit)="data">
            <template v-if="data.item.bcVerification.hash == ''">
              <b-icon-pencil-fill
                class="floated-right thrash-icon"
                @click="EditListItem(data.item)"
              ></b-icon-pencil-fill>
            </template>
            <template v-else> </template>
          </template>
        </b-table>
        <b-modal
          :id="'modal-xl'"
          size="xl"
          title="Tanúsítvány szerkesztése"
          no-stacking
          ok-only
          hide-footer
        >
          <form action="" class="certification-form">
            <div class="section">
              <p class="create-content">Képző adatai</p>

              <label for="institution-input"
                >Kérem válasszon intézményt &#42;</label
              >
              <b-form-select
                id="institution-input"
                class="select-input"
                v-model="elementEdit.institute.id"
                :options="modal.institutions"
                text-field="name"
                value-field="id"
              ></b-form-select>

              <label for="qualification-input"
                >Kérem válasszon képzést &#42;</label
              >
              <b-form-select
                id="qualification-input"
                class="select-input"
                v-model="elementEdit.course.id"
                :options="modal.courses"
                text-field="name"
                value-field="id"
              ></b-form-select>

              <label for="qualificationDateFrom-input"
                >Kérem adja meg a képzés kezdetét &#42;</label
              >
              <b-form-input
                class="text-input"
                id="qualificationDateFrom-input"
                type="date"
                v-model="elementEdit.course.fromDate"
              ></b-form-input>

              <label for="qualificationDateTo-input"
                >Kérem adja meg a képzés végét &#42;</label
              >
              <b-form-input
                class="text-input"
                id="qualificationDateTo-input"
                type="date"
                v-model="elementEdit.course.toDate"
              ></b-form-input>
            </div>

            <div class="section">
              <p class="create-content">Hallgató adatai</p>

              <label for="studentFamilyName-input"
                >Kérem adja meg a hallgató vezetéknevét</label
              >
              <b-form-input
                id="studentFamilyName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.familyName"
              >
              </b-form-input>

              <label for="studentFirstName-input"
                >Kérem adja meg a hallgató keresztnevét</label
              >
              <b-form-input
                id="studentFirstName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.firstName"
              >
              </b-form-input>

              <label for="studentId1-input"
                >Kérem adja meg a hallgató azonosítóját</label
              >
              <b-form-input
                id="studentId1-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.code1"
              >
              </b-form-input>

              <label for="studentFirstName-input"
                >Kérem adja meg a hallgató másodlagos azonosítóját</label
              >
              <b-form-input
                id="studentFirstName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.code2"
              >
              </b-form-input>

              <label for="studentBirthDate-input"
                >Kérem adja meg a hallgató születési dátumát</label
              >
              <b-form-input
                class="text-input"
                id="studentBirthDate-input"
                type="date"
                v-model="elementEdit.student.birthDate"
              ></b-form-input>

              <label for="studentMotherName-input"
                >Kérem adja meg a hallgató anyja nevét</label
              >
              <b-form-input
                id="studentMotherName-input"
                class="text-input"
                type="text"
                v-model="elementEdit.student.mothersMaidenName"
              >
              </b-form-input>
            </div>

            <div class="section">
              <p class="create-content">Vizsga adatai</p>
              <label for="certificationGrade-input"
                >Kérem válasszon vizsgajegyet</label
              >
              <b-form-select
                id="certificationGrade-input"
                class="select-input"
                v-model="elementEdit.grade.id"
                :options="modal.grades"
                text-field="name"
                value-field="id"
              >
              </b-form-select>

              <label for="certificationDate-input"
                >Kérem adja meg a vizsda dátumát</label
              >
              <b-form-input
                id="certificationDate-input"
                class="text-input"
                type="date"
                v-model="elementEdit.certificateDate"
              ></b-form-input>
            </div>
          </form>
          <button class="login-button add-button" @click="Edit" block>
            Mentés
          </button>
          <button class="login-button add-button" @click="Close" block>
            Mégse
          </button>
        </b-modal>
        <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
        <button class="login-button" id="login-button-style" @click="Hash">
          Hash létrehozása
        </button>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import errormixin from "@/mixins/errorMixin";
// Bootstrap Vue használatával
export default {
  name: "ListCertifications",

  data() {
    return {
      fields: [
        { key: "selected", label: "Kiválasztott" },
        //{ key: "show_details", label: "Részletek" },
        {
          key: "certificationId",
          label: "Tanusítvány sorszáma",
          sortable: true,
        },
        { key: "institution", label: "Intézmény neve", sortable: true },
        { key: "qualification", label: "Képzés neve", sortable: true },
        {
          key: "qualificationDate",
          label: "Képzés időtartama",
          sortable: true,
        },
        { key: "participantName", label: "Képzett neve", sortable: true },
        { key: "participantBirth", label: "Születési dátuma", sortable: true },
        {
          key: "certificationGrade",
          label: "Képzés minősítése",
          sortable: true,
        },
        {
          key: "certificationDate",
          label: "Tanúsítvány kiállításának dátuma",
          sortable: true,
        },
        {
          key: "certificationSigned",
          label: "Tanúsítványt aláírók nevei",
          sortable: true,
        },
        { key: "edit", label: "" },
      ],
      certifications: [],
      modal: {
        institutions: [],
        examTypes: [],
        grades: [],
        courses: [],
        examiners: [],
      },
      elementEditId: "",
      elementEdit: {
        institute: {
          id: "",
        },
        course: {
          id: "",
          fromDate: "",
          toDate: "",
        },
        student: {
          code1: "",
          code2: "",
          familyName: "",
          firstName: "",
          birthDate: "",
          mothersMaidenName: "",
        },
        grade: { id: "" },
        certificateDate: "",
      },
      selectedCertificates: [],
      filter: null,
      debounce: null,
      isBusy: true,
      filters: {
        institute: "",
        course: "",
        studentName: "",
        studentBirth: "",
        grade: "",
        certificateDate: "",
        signed: "",
      },
      perPage: 15,
      currentPage: 1,
      errorMessage: "",
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/certificates?isVerified=false")
      .then((response) => {
        this.certifications = response.data;
        this.isBusy = false;
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            this.errorMessage = "Érvénytelen felhasználónév vagy jelszó!";
            alert(this.errorMessage);
            break;
          case 404:
            this.errorMessage = "A weboldal nem érhető el.";
            alert(this.errorMessage);
            break;
          case 504:
            this.errorMessage = "Nincs szerver kapcsolat.";
            alert(this.errorMessage);
            break;
          default:
            this.errorMessage = "Valami hiba történt.";
            alert(this.errorMessage);
        }
      });
  },
  computed: {
    rows() {
      return this.certifications.length;
    },
  },
  methods: {
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("siker");
        this.isBusy = true;
        this.$http
          .get(
            "/api/certificates?isVerified=false&institute=" +
              this.filters.institute +
              "&course=" +
              this.filters.course +
              "&code=" +
              this.filters.studentId +
              "&studentName=" +
              this.filters.studentName +
              "&studentBirthDate=" +
              this.filters.studentBirth +
              "&grade=" +
              this.filters.grade +
              "&supervisor=" +
              this.filters.signed +
              "&certificateDate=" +
              this.filters.certificateDate
          )
          .then((response) => {
            this.certifications = response.data;
            console.log("kérés");
            this.isBusy = false;
            this.certifications.forEach((element) => {
              if (element.bcVerification.hash != "") {
                element._rowVariant = "primary";
              }
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      }, 600);
      console.log(this.debounce);
    },
    checkboxCheckedState() {
      if (this.$refs.AllRowSelect.checked == false) {
        this.$refs.selectableTable.clearSelected();
      }
      if (this.$refs.AllRowSelect.checked == true) {
        //do something
        this.$refs.selectableTable.selectAllRows();
      }
    },
    onRowSelected(items) {
      const probas = [];
      items.forEach((item) => {
        probas.push(item.id);
      });
      this.selectedCertificates = probas;
      if (this.selectedCertificates.length != this.perPage) {
        this.$refs.AllRowSelect.checked = false;
      }
      if (this.selectedCertificates.length == this.perPage) {
        this.$refs.AllRowSelect.checked = true;
      }
    },
    Hash() {
      this.$http.put("/api/certificates", this.selectedCertificates).then(
        alert("Sikeresen létre jött a hash kód"),
        (this.isBusy = true),
        this.$http
          .get("/api/certificates?isVerified=false")
          .then((response) => {
            this.certifications = response.data;
            this.isBusy = true;
          })
          .catch((error) => {
            this.handleError(error);
          })
      );
    },
    EditListItem(item) {
      this.$http.get("api/institutes").then((response) => {
        this.modal.institutions = response.data;
      });
      this.$http.get("api/courses").then((response) => {
        this.modal.courses = response.data;
      });
      this.$http.get("api/examination-types").then((response) => {
        this.modal.examTypes = response.data;
      });
      this.$http.get("api/grades").then((response) => {
        this.modal.grades = response.data;
      });

      this.elementEditId = item.id;
      this.elementEdit.institute.id = item.institute.id;
      this.elementEdit.course.id = item.course.id;
      this.elementEdit.course.fromDate = item.course.fromDate;
      this.elementEdit.course.toDate = item.course.toDate;
      this.elementEdit.student = item.student;
      this.elementEdit.grade.id = item.grade.id;
      this.elementEdit.certificateDate = item.certificateDate;

      this.$bvModal.show("modal-xl");
    },
    Edit() {
      this.$http
        .put("api/certificates/" + this.elementEditId, this.elementEdit)
        .then(
          (this.isBusy = true),
          this.$http
            .get("/api/certificates?isVerified=false")
            .then((response) => {
              this.certifications = response.data;
              this.certifications.forEach((element) => {
                if (element.bcVerification.hash != "") {
                  element._rowVariant = "primary";
                }
              });
              this.isBusy = false;
            })
            .catch((error) => {
              this.handleError(error);
            })
        );
      this.$bvModal.hide("modal-xl");
    },
    Close() {
      this.$bvModal.hide("modal-xl");
    },
  },
};
</script>

<style></style>
