import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { FormFilePlugin } from "bootstrap-vue";
import axios from "axios";

import "@/assets/css/proba.css";

Vue.prototype.$http = axios;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(FormFilePlugin);

Vue.prototype.$token = {
    name: "",
    token: "",
};

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");