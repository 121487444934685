<template>
  <div class="page">
    <div class="content content-width-nav floated-right">
      <h2>Hash kód ellenőrzés (Vizsgák)</h2>

      <div>
        <b-table
          id="table"
          table-variant="light"
          sort-icon-left
          selectable
          ref="selectableTable"
          selected-variant="none"
          :fields="fields"
          :items="exams"
          :per-page="perPage"
          :current-page="currentPage"
          @row-selected="onRowSelected"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template slot="top-row">
            <td>
              <input
                type="checkbox"
                ref="AllRowSelect"
                @click="checkboxCheckedState"
              />
            </td>
            <td></td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.institute"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.course"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.studentName"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.studentBirth"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.studentId"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.examinationType"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.grade"
              />
            </td>
            <td>
              <b-input
                type="text"
                @input="debounceSearch"
                v-model="filters.examiner"
              />
            </td>
            <td>
              <b-input
                type="date"
                @input="debounceSearch"
                v-model="filters.examinationDate"
              />
            </td>
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span class="check-mark" aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>

          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              Részletek {{ row.detailsShowing ? "Elrejtése" : "Mutatása" }}
            </b-button>
          </template>
          //TODO adatok megváltoztatása a hash alapján
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"><b>Hash kód</b></b-col>
                <b-col>{{ row.item.bcVerification.hash }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"><b>Hitelesített</b></b-col>
                <b-col>{{
                  row.item.bcVerification.hash != "" ? "&check;" : "&#10060;"
                }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hitelesítés dátuma</b></b-col
                >
                <b-col>{{ row.item.bcVerification.date }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenőrzött</b></b-col
                >
                <b-col>{{
                  row.item.bcVerification.lastCheckDate != ""
                    ? "&check;"
                    : "&#10060;"
                }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2" class="text-sm-right"
                  ><b>Hash ellenörzés dátuma</b></b-col
                >
                <b-col>{{ row.item.bcVerification.lastCheckDate }}</b-col>
              </b-row>
              <b-row class="mb-2"> </b-row>
            </b-card>
          </template>

          <template #cell(institute)="data"
            >{{ data.item.institute.name }}
          </template>
          <template #cell(course)="data">
            {{ data.item.course.name }}
          </template>
          <template #cell(participantName)="data"
            >{{ data.item.student.familyName }}
            {{ data.item.student.firstName }}
          </template>
          <template #cell(participantBirth)="data"
            >{{ data.item.student.birthDate }}
          </template>
          <template #cell(participantId)="data"
            >{{ data.item.student.code1 }}
          </template>
          <template #cell(examinationType)="data"
            >{{ data.item.examinationType.name }}
          </template>
          <template #cell(grade)="data">{{ data.item.grade.name }} </template>
          <template #cell(examiner)="data"
            >{{ data.item.examiner.familyName }}
            {{ data.item.examiner.firstName }}
          </template>
          <template #cell(examDate)="data">{{ data.item.examDate }} </template>
        </b-table>
        <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
        <button class="login-button" id="login-button-style" @click="Hash()">
          Hash ellenörzése
        </button>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// Bootstrap Vue használatával
import errormixin from "@/mixins/errorMixin";
export default {
  name: "ExamHashValidation",

  data() {
    return {
      fields: [
        { key: "selected", label: "Kiválasztott" },
        { key: "show_details", label: "Részletek" },
        { key: "institute", label: "Intézmény neve", sortable: true },
        { key: "course", label: "Képzés neve" },
        { key: "participantName", label: "Hallgató neve", sortable: true },
        { key: "participantBirth", label: "Születési dátuma" },
        { key: "participantId", label: "Azonosítója" },
        { key: "examinationType", label: "Vizsga típusa" },
        { key: "grade", label: "Vizsga minősítése" },
        { key: "examiner", label: "Vizsgáztató neve" },
        { key: "examinationDate", label: "Vizsga dátuma" },
      ],
      exams: [],
      selectedExamination: {
        institute: "",
        course: "",
        student: {
          code1: "",
          code2: "",
          familyName: "",
          firstName: "",
          birthDate: "",
          mothersMaidenName: "",
        },
        debounce: null,
        examinationType: "",
        grade: "",
        examiner: {
          id: "",
          familyName: "",
          firstName: "",
        },
        examinationDate: "",
      },
      selectedExaminations: [],
      hashesWithIDs: [
        {
          id: "",
          hash: "",
        },
      ],
      perPage: 15,
      currentPage: 1,
      errorMessage: "",
      filter: null,
      isBusy: true,
      filters: {
        institute: "",
        course: "",
        studentName: "",
        studentBirth: "",
        studentId: "",
        examinationType: "",
        grade: "",
        examiner: "",
        examinationDate: "",
      },
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/examinations?isVerified=true")
      .then((response) => {
        this.exams = response.data;
        this.exams.forEach((element) => {
          if (element.bcVerification.hash != "") {
            element._rowVariant = "primary";
          }
          if (
            element.bcVerification.date != element.bcVerification.lastCheckDate
          ) {
            element._rowVariant = "success";
          }
          this.isBusy = false;
          console.log(Object.keys(this.exams).length);
        });
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  computed: {
    rows() {
      return this.exams.length;
    },
  },

  methods: {
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        console.log("siker");
        this.isBusy = true;
        this.$http
          .get(
            "/api/examinations?isVerified=true&institute=" +
              this.filters.institute +
              "&course=" +
              this.filters.course +
              "&code=" +
              this.filters.studentId +
              "&studentName=" +
              this.filters.studentName +
              "&studentBirthDate=" +
              this.filters.studentBirth +
              "&examinationType=" +
              this.filters.examinationType +
              "&grade=" +
              this.filters.grade +
              "&examinerName=" +
              this.filters.examiner +
              "&examinationDate=" +
              this.filters.examinationDate
          )
          .then((response) => {
            this.exams = response.data;
            console.log("kérés");
            this.isBusy = false;
            this.exams.forEach((element) => {
              if (element.bcVerification.hash != "") {
                element._rowVariant = "primary";
              }
              if (
                element.bcVerification.date !=
                element.bcVerification.lastCheckDate
              ) {
                element._rowVariant = "success";
              }
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      }, 600);
      console.log(this.debounce);
    },
    checkboxCheckedState() {
      if (this.$refs.AllRowSelect.checked == false) {
        this.$refs.selectableTable.clearSelected();
      }
      if (this.$refs.AllRowSelect.checked == true) {
        //do something
        this.$refs.selectableTable.selectAllRows();
      }
    },
    replaceTinDateTime(item) {
      item.replace("T", " ");

      return item;
    },
    onRowSelected(items) {
      const selectedExams = [];
      const probas = [];
      items.forEach((item) => {
        const selectedExamination = {
          institute: item.institute.name,
          course: item.course.name,
          student: {
            code1: item.student.code1,
            code2: item.student.code2,
            familyName: item.student.familyName,
            firstName: item.student.firstName,
            birthDate: item.student.birthDate,
            mothersMaidenName: item.student.mothersMaidenName,
          },
          examinationType: item.examinationType.name,
          grade: item.grade.name,
          examiner: {
            id: item.examiner.id,
            familyName: item.examiner.familyName,
            firstName: item.examiner.firstName,
          },
          examinationDate: item.examinationDate,
        };
        const hashIDs = { id: item.id, hash: item.bcVerification.hash };
        probas.push(hashIDs);

        selectedExams.push(selectedExamination);
      });

      this.selectedExaminations = selectedExams.map((a) => {
        return { ...a };
      });
      this.hashesWithIDs = probas.map((a) => {
        return { ...a };
      });

      if (Object.keys(this.hashesWithIDs).length != this.perPage.length) {
        this.$refs.AllRowSelect.checked = false;
      }
      if (Object.keys(this.hashesWithIDs).length == this.perPage.length) {
        this.$refs.AllRowSelect.checked = true;
      }
    },
    Hash() {
      this.$http
        .post("/api/examination-hash-codes", this.selectedExaminations)
        .then((response) => {
          this.checkexaminations(response.data);
          this.$refs.selectableTable.clearSelected();
          this.debounceSearch();
          alert("Az ellenörzés megtörtént");

          this.selectedExaminations = [];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkexaminations(serverHashcodes) {
      var hashes = serverHashcodes;

      for (let index = 0; index < this.hashesWithIDs.length; index++) {
        const element = this.hashesWithIDs[index];

        if (element.hash == hashes[index]) {
          this.exams.find((exam) => {
            if (exam.id === element.id) {
              exam._rowVariant = "success";
            }
          });
        } else {
          this.exams.find((exam) => {
            if (exam.id === element.id) {
              exam._rowVariant = "danger";
            }
          });
        }
      }
    },
  },
};
</script>

<style></style>
