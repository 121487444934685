<template>
  <div class="page">
    <div class="content list-editor">
      <h2>Vizsgáztatók</h2>
      <form action="">
        <b-table
          thead-class="hidden_header"
          id="table"
          class="table-content"
          table-variant="light"
          :fields="fields"
          :items="this.list"
          :per-page="perPage"
          :current-page="currentPage"
          ref="table"
        >
          <template #cell(teacher)="data">
            {{ data.item.firstName }} {{ data.item.familyName }}
            <b-icon-trash-fill
              class="floated-right thrash-icon"
              @click="
                Remove(data.item.id, data.item.firstName, data.item.familyName)
              "
            ></b-icon-trash-fill>
            <b-icon-pencil-fill
              class="floated-right thrash-icon"
              @click="EditListItem(data.item)"
            ></b-icon-pencil-fill>
          </template>
        </b-table>
        <b-modal
          :id="'modal-center'"
          title="Vizsgáztató szerkesztése"
          no-stacking
          ok-only
          hide-footer
        >
          <form class="inline-elements">
            <div class="inline-elements">
              <input
                placeholder="Felhasználónév"
                type="text"
                class="list-editor-input"
                v-model="elementEdit.name"
              />
              <input
                placeholder="Jelszó"
                type="password"
                class="list-editor-input"
                v-model="elementEdit.password"
              />
              <input
                placeholder="Családnév"
                type="text"
                class="list-editor-input"
                v-model="elementEdit.familyName"
              />
              <input
                placeholder="Keresztnév"
                type="text"
                class="list-editor-input"
                v-model="elementEdit.firstName"
              />

              <input
                placeholder="E-mail"
                type="text"
                class="list-editor-input"
                v-model="elementEdit.email"
              />
              <input
                placeholder="Telefon szám"
                type="text"
                class="list-editor-input"
                v-model="elementEdit.phone"
              />
              <input
                placeholder="Születési dátum"
                type="date"
                class="list-editor-input"
                v-model="elementEdit.birthDate"
              />
            </div>
            <button class="login-button add-button" @click="Edit" block>
              Mentés
            </button>
          </form>
        </b-modal>
        <b-pagination
          variant="dark"
          class="pagination-place"
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>

        <span>{{ errorMessage }}</span>
        <h3>Új vizsgáztató hozzáadása</h3>
        <div class="inline-elements">
          <input
            placeholder="Felhasználónév"
            type="text"
            class="list-editor-input"
            v-model="element.name"
          />
          <input
            placeholder="Jelszó"
            type="password"
            class="list-editor-input"
            v-model="element.password"
          />
          <input
            placeholder="Családnév"
            type="text"
            class="list-editor-input"
            v-model="element.familyName"
          />
          <input
            placeholder="Keresztnév"
            type="text"
            class="list-editor-input"
            v-model="element.firstName"
          />

          <input
            placeholder="E-mail"
            type="text"
            class="list-editor-input"
            v-model="element.email"
          />
          <input
            placeholder="Telefon szám"
            type="text"
            class="list-editor-input"
            v-model="element.phone"
          />
          <input
            placeholder="Születési dátum"
            type="date"
            class="list-editor-input"
            v-model="element.birthDate"
          />
          <button class="login-button add-button" @click="Add">
            Vizsgáztató hozzáadása
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormixin from "@/mixins/errorMixin";
export default {
  name: "TeacherListEditor",

  data() {
    return {
      fields: [{ key: "teacher", label: "Tanár", sortable: true }],
      list: [],
      users: [],
      perPage: 10,
      currentPage: 1,
      errorMessage: "",
      surName: "",
      lastName: "",
      element: {
        name: "",
        password: "",
        familyName: "",
        firstName: "",
        email: "",
        phone: "",
        birthDate: "",
      },
      elementEditId: "",
      elementEdit: {
        name: "",
        password: "",
        familyName: "",
        firstName: "",
        email: "",
        phone: "",
        birthDate: "",
      },
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/users")
      .then((response) => {
        this.list = response.data;
        console.log(this.list);
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  computed: {
    rows() {
      return this.list.length;
    },
    IsDisabled: function () {
      if (this.element == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    EditListItem(item) {
      console.log(item);
      this.elementEditId = item.id;
      this.elementEdit.name = item.name;
      this.elementEdit.password = "";
      this.elementEdit.familyName = item.familyName;
      this.elementEdit.firstName = item.firstName;
      this.elementEdit.email = item.email;
      this.elementEdit.phone = item.phone;
      this.elementEdit.birthDate = item.birthDate;
      this.$bvModal.show("modal-center");
    },
    Refresh() {
      this.$http
        .get("/api/users")
        .then((response) => {
          this.list = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    Add() {
      this.$http.post("api/users", this.element).then();

      this.Refresh();

      this.element = "";
    },
    Edit() {
      this.$http
        .put("api/users/" + this.elementEditId, this.elementEdit)
        .then();
      this.$bvModal.hide("modal-center");
      this.Refresh();
    },
    Remove(index, firstName, familyName) {
      if (
        confirm(
          "Biztos törli a(z) " + firstName + " " + familyName + " vizsgáztatót?"
        )
      ) {
        this.$http
          .delete("api/users/" + index)
          .then((response) => console.log(response));
        this.Refresh();
      }
    },
  },
};
</script>
