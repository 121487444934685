<template>
  <div class="nav-container">
    <div id="nav" v-if="this.$token.name != ''">
      <b-nav vertical>
        <b-nav-item v-b-toggle.collapse-editors
          ><b-icon-file-earmark-bar-graph
            class="nav-icon"
          ></b-icon-file-earmark-bar-graph>
          Törzsadatok
          <b-icon-caret-down-fill class="floated-right"></b-icon-caret-down-fill
        ></b-nav-item>
        <b-collapse id="collapse-editors">
          <b-nav-item class="sub-menu" to="/InstitutionListEditor"
            ><b-icon-building class="nav-icon"></b-icon-building
            >Intézmények</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/QualificationListEditor">
            <b-icon-journal-album class="nav-icon"></b-icon-journal-album>
            Képzések</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/TeachersListEditor"
            ><i class="bi bi-mortarboard-fill nav-icon"></i
            >Vizsgáztatók</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/ExamTypeListEditor"
            ><b-icon-clipboard-data class="nav-icon"></b-icon-clipboard-data
            >Vizsgatípusok</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/ExamGradeListEditor"
            ><b-icon-award class="nav-icon"></b-icon-award
            >Vizsgajegyek</b-nav-item
          >
        </b-collapse>
        <b-nav-item v-b-toggle.collapse-exam
          ><b-icon-pencil-square class="nav-icon"></b-icon-pencil-square>
          Vizsgák<b-icon-caret-down-fill
            class="floated-right"
          ></b-icon-caret-down-fill
        ></b-nav-item>
        <b-collapse id="collapse-exam">
          <b-nav-item class="sub-menu" to="/CreateNewExam"
            ><b-icon-plus-square class="nav-icon"></b-icon-plus-square
            >Felvitel</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/CreateHashFromExam"
            ><b-icon-file-earmark-plus
              class="nav-icon"
            ></b-icon-file-earmark-plus
            >Hash kódolás blockchain hitelesítéssel
          </b-nav-item>
          <b-nav-item class="sub-menu" to="/ExamHashValidation"
            ><b-icon-check2-circle class="nav-icon"></b-icon-check2-circle>Hash
            kód ellenőrzés</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/ListExams"
            ><b-icon-table class="nav-icon"></b-icon-table>Listák</b-nav-item
          >
        </b-collapse>
        <b-nav-item v-b-toggle.collapse-certification
          ><b-icon-bookmark-check class="nav-icon"></b-icon-bookmark-check
          >Tanúsítványok<b-icon-caret-down-fill
            class="floated-right"
          ></b-icon-caret-down-fill
        ></b-nav-item>
        <b-collapse id="collapse-certification">
          <b-nav-item class="sub-menu" to="/CreateNewCertification"
            ><b-icon-plus-square class="nav-icon"></b-icon-plus-square
            >Felvitel</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/CreateHashFromCertifacate"
            ><b-icon-file-earmark-plus
              class="nav-icon"
            ></b-icon-file-earmark-plus
            >Hash kódolás blockchain hitelesítéssel
          </b-nav-item>
          <b-nav-item class="sub-menu" to="/CertificateHashValidation"
            ><b-icon-check2-circle class="nav-icon"></b-icon-check2-circle>Hash
            kód ellenőrzés</b-nav-item
          >
          <b-nav-item class="sub-menu" to="/ListCertifications"
            ><b-icon-table class="nav-icon"></b-icon-table>Listák</b-nav-item
          >
        </b-collapse>
        <!-- <b-nav-item to="/login">Bejelentkezés</b-nav-item> -->
        <b-nav-item @click="logout">Kijelentkezés</b-nav-item>
      </b-nav>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import "bootstrap";
export default {
  name: "Nav",
  data() {
    return {
      show: true,
      user: this.$token,
    };
  },
  methods: {
    logout() {
      this.$token.name = "";
      this.$token.token = "";
      this.$router.push("Login");
    },
  },
};
</script>

<style></style>
