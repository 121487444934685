export default {
    methods: {
        handleError(error) {
            switch (error.response.status) {
                case 401:
                    this.errorMessage = "Érvénytelen felhasználónév vagy jelszó!";

                    break;
                case 404:
                    this.errorMessage = "A weboldal nem érhető el.";
                    alert(this.errorMessage);
                    break;
                case 504:
                    this.errorMessage = "Nincs szerver kapcsolat.";
                    alert(this.errorMessage);
                    break;
                default:
                    this.errorMessage = "Valami hiba történt.";
                    alert(this.errorMessage);
            }
        },
    },
};