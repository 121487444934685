<template>
  <div class="page">
    <div class="content list-editor">
      <h2>Intézmények</h2>
      <form action="">
        <b-table
          id="table"
          class="table-content"
          table-variant="light"
          :fields="fields"
          :items="this.institutions"
          :per-page="perPage"
          :current-page="currentPage"
          ref="table"
        >
          <template #cell(institution)="data">
            {{ data.item.name }}
          </template>
          <template #cell(supervisor)="data">
            {{ data.item.supervisor }}
          </template>
          <template #cell(position)="data">
            {{ data.item.position }}

            <b-icon-trash-fill
              class="floated-right thrash-icon"
              @click="Remove(data.item.id, data.item.name)"
            ></b-icon-trash-fill>
            <b-icon-pencil-fill
              class="floated-right thrash-icon"
              @click="EditListItem(data.item)"
            ></b-icon-pencil-fill>
          </template>
        </b-table>

        <b-modal
          :id="'modal-center'"
          title="Intézmény szerkesztése"
          no-stacking
          ok-only
          hide-footer
        >
          <form class="inline-elements">
            <div>
              <input
                class="modal-input"
                type="text"
                v-model="elementEdit.name"
              />
              <input
                class="modal-input"
                type="text"
                v-model="elementEdit.supervisor"
              />
              <input
                class="modal-input"
                type="text"
                v-model="elementEdit.position"
              />
            </div>
            <button class="login-button add-button" @click="Edit" block>
              Mentés
            </button>
          </form>
        </b-modal>
        <b-pagination
          variant="dark"
          class="pagination-place"
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
        <span>{{ errorMessage }}</span>
        <h3>Új intézmény hozzáadása</h3>
        <div class="inline-elements">
          <input
            placeholder="intézmény"
            type="text"
            class="list-editor-input"
            v-model="element.name"
          />
          <input
            placeholder="Tanusítvány hitelesítő"
            type="text"
            class="list-editor-input"
            v-model="element.supervisor"
          />
          <input
            placeholder="Pozíció"
            type="text"
            class="list-editor-input"
            v-model="element.position"
            v-on:keyup.enter="Add"
          />
          <button
            class="login-button add-button"
            :disabled="IsDisabled"
            @click="Add"
          >
            Intézmény hozzáadása
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormixin from "@/mixins/errorMixin";
export default {
  name: "InstitutionListEditor",
  data() {
    return {
      fields: [
        { key: "institution", label: "Intézmények", sortable: true },
        { key: "supervisor", label: "Tanusítvány hitelesítő" },
        { key: "position", label: "beosztása" },
      ],
      institutions: [],
      perPage: 10,
      currentPage: 1,
      errorMessage: "",
      element: { name: "", supervisor: "", position: "" },
      elementEdit: { id: "", name: "", supervisor: "", position: "" },
    };
  },
  mixins: [errormixin],
  created: function () {
    this.$http
      .get("/api/institutes?offset=0&limit=50")
      .then((response) => {
        console.log(response.data);
        this.institutions = response.data;
      })
      .catch((error) => {
        this.handleError(error);
      });
  },
  computed: {
    rows() {
      return this.institutions.length;
    },
    IsDisabled() {
      if (
        this.element.name.trim() == "" ||
        this.element.supervisor == "" ||
        this.element.position == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    EditListItem(item) {
      this.elementEdit.id = item.id;
      this.elementEdit.name = item.name;
      this.elementEdit.supervisor = item.supervisor;
      this.elementEdit.position = item.position;
      this.$bvModal.show("modal-center");
    },
    Refresh() {
      this.$http
        .get("/api/institutes")
        .then((response) => {
          this.institutions = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    Add() {
      this.$http.post("api/institutes", this.element).then();

      this.element.name = "";
      this.Refresh();
    },
    Edit() {
      this.$http
        .put("api/institutes/" + this.elementEdit.id, {
          name: this.elementEdit.name,
          supervisor: this.elementEdit.supervisor,
          position: this.elementEdit.position,
        })
        .then();
      this.$bvModal.hide("modal-center");
      this.Refresh();
    },
    Remove(index, element) {
      if (confirm("Biztos törli a(z) " + element + " intézményt?")) {
        this.$http.delete("api/institutes/" + index);
        this.Refresh();
      }
    },
  },
};
</script>

<style></style>
